import React, { useState, useEffect } from "react";
import "./GuideBox.css";

const GuideBox = ({children}) => {
  return (
    <div className="boxStyle">
    {children}
    </div>
  );
}

export default GuideBox;